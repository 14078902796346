import React from 'react';
import { TextField, IconButton, CircularProgress } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

const ChatInput = ({ input, isLoading, onInputChange, onSubmit }) => {
  return (
    <form onSubmit={onSubmit} className="p-4">
      <div className="relative flex items-center">
        <TextField
          fullWidth
          value={input}
          onChange={(e) => onInputChange(e.target.value)}
          placeholder="Type your message..."
          disabled={isLoading}
          variant="outlined"
          sx={{
            '& .MuiOutlinedInput-root': {
              backgroundColor: 'black',
              '& input': {
                color: 'white'
              },
              borderRadius: '9999px',
              '& fieldset': {
                borderColor: 'rgba(255, 255, 255, 0.23)',
                borderRadius: '9999px'
              },
              '&:hover fieldset': {
                borderColor: 'rgba(255, 255, 255, 0.4)'
              },
              '&.Mui-focused fieldset': {
                borderColor: '#2563eb'
              },
              '&.Mui-disabled': {
                '& input': {
                  color: 'rgba(255, 255, 255, 0.5)'
                }
              }
            },
            '& .MuiOutlinedInput-input': {
              padding: '0.75rem 3rem 0.75rem 1.5rem',
              '&::placeholder': {
                color: 'rgba(255, 255, 255, 0.5)',
                opacity: 1
              }
            }
          }}
          InputProps={{
            endAdornment: (
              <IconButton
                type="submit"
                disabled={isLoading}
                className="absolute right-1 bg-blue-600 text-white hover:bg-blue-700 disabled:bg-blue-800 disabled:cursor-not-allowed"
                sx={{
                  padding: '0.5rem',
                  minWidth: 'auto',
                  borderRadius: '9999px',
                  backgroundColor: '#2563eb',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#1d4ed8'
                  },
                  '&.Mui-disabled': {
                    backgroundColor: '#1e40af',
                    color: 'rgba(255, 255, 255, 0.5)'
                  }
                }}
              >
                {isLoading ? <CircularProgress size={20} className="text-white" /> : <SendIcon sx={{ fontSize: 20 }} />}
              </IconButton>
            )
          }}
        />
      </div>
    </form>
  );
};

export default ChatInput;
