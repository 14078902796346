import React from 'react';
import { Paper, Avatar, Typography } from '@mui/material';
import AmiLogo from '../../assets/images/Asset 9@8x-neophyte-black.png';

const ChatMessage = ({ text, isUser }) => {
  return (
    <div className={`flex ${isUser ? 'justify-end' : 'justify-start'}   `}>
      {!isUser && (
        <Avatar
          src={AmiLogo}
          alt="Ami Logo"
          sx={{
            width: 40,
            height: 40,
            marginRight: '0.2rem',
            backgroundColor: 'black',
            border: '3px solid #ca8a04',
            // border:'2px solid red',
            padding: '4px',
            flexShrink: 0,
            '& img': {
              width: '20px',
              height: '20px'
            }
          }}
        />
      )}
      <Paper
        elevation={1}
        className={`max-w-[80%] rounded-2xl p-4 relative border border-gray-600  ${
          isUser ? 'bg-[#003748] text-white' : 'bg-black text-white'
        }`}
        sx={{
          backgroundColor: isUser ? '#003748' : 'black',
          color: 'white',
          '&.MuiPaper-root': {
            backgroundImage: 'none'
          }
        }}
      >
        {!isUser && (
          <Typography
            variant="h6"
            component="div"
            className="font-bold mb-2 text-lg  text-white"
            sx={{
              fontWeight: 'bold',
              marginBottom: '0.5rem',
              fontSize: '1.125rem'
            }}
          >
            Ami
          </Typography>
        )}
        <Typography
          component="p"
          className="whitespace-pre-wrap text-base"
          sx={{
            whiteSpace: 'pre-wrap',
            fontSize: '1rem'
          }}
        >
          {text}
        </Typography>
      </Paper>
    </div>
  );
};

export default ChatMessage;
