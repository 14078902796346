import React, { useState, useRef, useEffect } from 'react';
import { Paper } from '@mui/material';
// import SendIcon from '@mui/icons-material/Send';

// import AmiChat from './AmiChat';
import ChatMessage from './ChatMessage';
import ChatInput from './ChatInput';

// Message component with MUI styling
// const ChatMessage = ({ text, isUser }) => (
//   <div className={`flex ${isUser ? 'justify-end' : 'justify-start'}`}>
//     <Paper
//       elevation={1}
//       className={`
//         max-w-[80%] p-4 break-words
//         ${isUser ?
//           'bg-blue-600 text-white' :
//           'bg-gray-800 text-white'
//         }
//       `}
//     >
//       <div className="whitespace-pre-wrap">{text}</div>
//     </Paper>
//   </div>
// );

// Input component with MUI styling
// const ChatInput = ({ input, isLoading, onInputChange, onSubmit }) => (
//   <form onSubmit={onSubmit} className="p-4 bg-gray-900">
//     <div className="flex gap-2">
//       <TextField
//         fullWidth
//         variant="outlined"
//         value={input}
//         onChange={(e) => onInputChange(e.target.value)}
//         disabled={isLoading}
//         placeholder="Type your message..."
//         sx={{
//           '& .MuiOutlinedInput-root': {
//             color: 'white',
//             '& fieldset': {
//               borderColor: 'rgba(255, 255, 255, 0.23)',
//             },
//             '&:hover fieldset': {
//               borderColor: 'rgba(255, 255, 255, 0.4)',
//             },
//             '&.Mui-focused fieldset': {
//               borderColor: 'primary.main',
//             },
//           },
//           '& .MuiOutlinedInput-input': {
//             '&::placeholder': {
//               color: 'rgba(255, 255, 255, 0.5)',
//               opacity: 1,
//             },
//           },
//         }}
//       />
//       <IconButton
//         type="submit"
//         disabled={isLoading}
//         className="text-white hover:bg-blue-700"
//       >
//         {isLoading ? (
//           <CircularProgress size={24} className="text-blue-500" />
//         ) : (
//           <SendIcon />
//         )}
//       </IconButton>
//     </div>
//   </form>
// );

const AmiChat = () => {
  const [messages, setMessages] = useState([
    {
      text: "Hello, I'm Ami.\nIts nice to talk to you!\nSome initial info - I'm an AI assistant created to showcase our company's LLM capabilities.\nFeel free to ask me anything!",
      isUser: false
    }
  ]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const messagesContainerRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (input.trim() && !isLoading) {
      const userMessage = input.trim();
      setInput('');
      setMessages((prev) => [...prev, { text: userMessage, isUser: true }]);
      setIsLoading(true);

      try {
        const response = await fetch('https://6mkygxqqz3.execute-api.ap-south-1.amazonaws.com/store_analysis/ami_fashion/v1', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            prompt: userMessage
          })
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const result = await response.json();

        // const prettyJsonString = JSON.stringify(result.data[0], null, 2);
        //   console.log("ami agent result", prettyJsonString);

        // result.data.map(res=>
        //   setMessages((prev) => [
        //     ...prev,
        //     {
        //       text: JSON.stringify(res, null, 2),
        //       isUser: false
        //     }
        //   ])

        // )

        setMessages((prev) => [
          ...prev,
          {
            text: result.data.res,
            isUser: false
          }
        ]);
      } catch (error) {
        setMessages((prev) => [
          ...prev,
          {
            text: "I apologize, but I'm having trouble connecting right now. Please try again later.",
            isUser: false
          }
        ]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const handleScroll = (e) => {
    e.stopPropagation();
  };

  return (
    <div className=" max-w-6xl mx-auto bg-black flex items-center justify-center scrollbar rounded-lg p-4">
      <Paper
        elevation={3}
        className="w-full bg-gray-900 rounded-3xl overflow-hidden shadow-xl flex flex-col h-[calc(100vh-2rem)] max-h-[80vh]"
      >
        <div className="flex-grow overflow-y-auto p-6 space-y-6 scrollbar" ref={messagesContainerRef} onScroll={handleScroll}>
          {messages.map((message, index) => (
            <ChatMessage key={index} text={message.text} isUser={message.isUser} />
          ))}
          <div ref={messagesEndRef} />
        </div>
        <ChatInput input={input} isLoading={isLoading} onInputChange={setInput} onSubmit={handleSubmit} />
      </Paper>
    </div>
  );
};

export default AmiChat;
